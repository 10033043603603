<template>
    <div class="reviews">
        <h2 class="reviews__title">
            {{ t("reviews.title") }}
        </h2>
        <p class="reviews__text">
            {{ t("reviews.description") }}
        </p>
        <div class="reviews__rate">
            <span class="reviews__rate__number" v-text="rate"/>
            <img v-for="i in fullStarsNumber(rate)" src="../assets/svg/star-full.svg" alt="">
            <img v-for="i in emptyStarsNumber(rate)"  src="../assets/svg/star-empty.svg" alt="">
            <img src="../assets/svg/Google__Logo.svg" alt="" class="reviews__rate__logo">
        </div>
        <div class="swiper-box">
            <swiper-container 
                class="reviews__swiper" 
                navigation-next-el=".swiper-button-next"
                navigation-prev-el=".swiper-button-prev"
                space-between="8"
                slides-per-view="auto"
                centered-slides="true"
                auto-height="true"
                :breakpoints="swiperBreakpoints"
            >
                <swiper-slide 
                    class="swiper-slide__wrapper" 
                    v-for="review in content['review_section_items']"
                    :key="review.id"
                >
                    <div class="swiper-slide">
                    <h4 class="swiper-slide__title" v-text="review['name_' + langCode.value]" />
                    <div class="swiper-slide__rate">
                        <img v-for="i in fullStarsNumber(review['score']/10)" src="../assets/svg/star-full.svg" alt="" class="swiper-slide__rate__img">
                        <img v-for="i in emptyStarsNumber(review['score']/10)"  src="../assets/svg/star-empty.svg" alt="" class="swiper-slide__rate__img">
                    </div>
                    <p class="swiper-slide__text">
                        {{ review['text_' + langCode.value] }}
                    </p>
                </div>
                </swiper-slide>
            </swiper-container>
            <div class="swiper-navigation">
                <div class="swiper-button swiper-button-prev">
                    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Frame 1000004130">
                            <path id="Vector 51" d="M24.1666 29.2561L15.8333 20.9228L24.1666 12.5894" stroke-width="4"/>
                        </g>
                    </svg>
                </div>
                <div class="swiper-button swiper-button-next">
                    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Frame 1000004129">
                            <path id="Vector 51" d="M15.8333 29.2561L24.1666 20.9228L15.8333 12.5894" stroke-width="4"/>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
        <div class="reviews__btn">
            <BaseButton 
                :value='content["review_section_button_text_" + langCode.value]'
                @handleClick="scrollToForm()"
            />
        </div>
    </div>
</template>

<script setup>
import BaseButton from '@/components/Base/BaseButton.vue';
import { ref, computed } from 'vue'
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n'
import { register } from 'swiper/element/bundle';
register();

const store = useStore()
const {t} = useI18n()

const langCode = computed(() => {
  return useI18n().locale
})

const content = computed(() => {
  return store.state.pageContent
})

const rate = ref(4.8)

const fullStarsNumber = (rating) => Math.round(rating)
const emptyStarsNumber = (rating) => 5 - Math.round(rating)

const swiperBreakpoints = {
    1024: {
        slidesPerView: 3,
        initialSlide: 1,
        spaceBetween: 75,
    }
}

const scrollToForm = () => {
  document?.querySelector('.form')?.scrollIntoView({behavior: "smooth"})
}
</script>

<style lang="scss" scoped>

</style>