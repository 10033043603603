<template>
    <div class="thank-you__wrapper">
        <div class="thank-you">
            <h2 class="thank-you__title">
                {{ t("thank-you.title") }}
            </h2>
            <div class="thank-you__icon__wrapper">
                <img src="../assets/svg/typVectorOne.svg" alt="" class="thank-you__icon">
                <div class="thank-you__arrow">
                    <div class="thank-you__arrow__line"></div>
                    <div class="thank-you__arrow__tip"></div>
                </div>
                <img src="../assets/svg/typVectorTwo.svg" alt="" class="thank-you__icon">
            </div>
            <p class="thank-you__paragraph">
                {{ t("thank-you.paragraph") }}
            </p>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
const { t } = useI18n() 

</script>

<style lang="scss" scoped>

</style>