const en = {
    "form": {
        "title": "Get compensation with DelayFix",
        "paragraph": "Just submit your application, and our consultant will contact you to arrange the details.",
        "name": "Name",
        "number": "Phone number",
        "mail": {
            "label": "Email address",
            "placeholder": "address{'@'}email.com"
        },
        "comment": {
            "btn": "Add a comment",
            "label": "Your comment",
            "label-sm": "(optional)",
            "placeholder": "Would you like to add something to your application?"
        },
        "error": {
            "empty": "This field cannot be empty",
            "invalid-email": "Please enter a valid email address",
            "invalid-tel": "Please enter a valid phone number",
            "global": "Wystąpił błąd. Spróbuj ponownie później.",
        },
        "btn": "Send"
    },
    "why": {
        "title": "Why DelayFix?",
        "paragraph": "We have extensive experience in handling the most challenging cases, including preliminary proceedings before the Court of Justice of the European Union (CJEU). We are not afraid of challenges. If necessary, our lawyers are ready to fly to the farthest corners of the world to personally oversee the enforcement of due compensation."
    },
    "reviews": {
        "title": "Our clients' reviews",
        "description": "Read the reviews of thousands of satisfied DelayFix clients",
    },
    "footer": {
        "text": "We support passengers affected by delayed or canceled flights",
        "contact": "We encourage you to contact us from Monday to Friday, 9:00 AM - 5:00 PM",
        "terms": "Terms of Service",
        "privacy": "Privacy Policy",
        "capital": "Share capital amount:"
    },
    "thank-you": {
        "title": "Thank you!",
        "paragraph": "Your application has been sent. Our consultant will contact you to arrange the details."
    }
}

export default en
