<template>
  <div class="form" id="form">
    <h3 class="form__title">
      {{ t("form.title") }}
    </h3>
    <p class="form__paragraph">
      {{ t("form.paragraph") }}
    </p>
    <form @submit.prevent="submitForm()">
      <BaseInput
          :label='t("form.name")'
          type="text"
          :placeholder='t("form.name")'
          :value="formValues.name"
          :error="errorName"
          @input-change="(val) => formValues.name = val"
          @input-blur="(target) => validateInput(target)"
      />
      <BasePhone
          :label='t("form.number")'
          :value="formValues.tel"
          :error="errorTel"
          @input-blur="(target) => validateTelInput(target)"
          @input-change="(val) => formValues.tel = val"
      />
      <BaseInput
          :label='t("form.mail.label")'
          type="email"
          :placeholder='t("form.mail.placeholder")'
          :value="formValues.mail"
          :error="errorMail"
          @input-change="(val) => formValues.mail = val"
          @input-blur="(target) => validateInput(target)"
      />
      <button
          v-if="!isComment"
          class="form__button"
          @click="isComment = true"
      >
        + {{ t("form.comment.btn") }}
      </button>
      <BaseInput
          v-if="isComment"
          :label='t("form.comment.label")'
          :label-sm='t("form.comment.label-sm")'
          type="textarea"
          :placeholder='t("form.comment.placeholder")'
          :value="formValues.comment"
          @input-change="(val) => {
                    commentLen = val.length;
                    formValues.comment = val;
                }"
          :error="errorTel"
      >
        <span class="input__el--textarea__len">{{ commentLen }}/500</span>
      </BaseInput>
      <BaseButton
          :value='t("form.btn")'
      />
      <div v-if="formError" class="error" style="margin-top: 12px;">
        {{ formError }}
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import {ref, computed, reactive} from 'vue'
import axios from 'axios';
import {useRouter} from 'vue-router';
import {useStore} from 'vuex';
import BaseInput from '@/components/Base/BaseInput.vue';
import BasePhone from '@/components/Base/BasePhone.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import {useI18n} from 'vue-i18n'

const {t, locale} = useI18n()
const router = useRouter()
const store = useStore()

const agencyId = computed(() => {
  return store.state.pageContent.id ?? ''
})

const isComment = ref(false);
const commentLen = ref(0);

const formValues = reactive({
  name: '',
  tel: '',
  mail: '',
  comment: '',
  agencyId: agencyId,
  language: locale
})

const formError = ref('')
const errorName = ref('')
const errorMail = ref('')
const errorTel = ref('')


const validateInput = (target) => {
  if (target.type === 'text') {
    if (!target.value.length) {
      errorName.value = t("form.error.empty")
    } else {
      errorName.value = ''
    }
  }

  if (target.type === 'email') {
    if (!target.value.length) {
      errorMail.value = t("form.error.empty")
    } else {
      let isValidMail = target.value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      if (!isValidMail) {
        errorMail.value = t("form.error.invalid-email")
      } else {
        errorMail.value = ''
      }
    }
  }
}

function validateTelInput(target) {
  if (!target.value.length) {
    errorTel.value = t("form.error.empty")
  } else {
    let isValidTel = target.value.match(/\d{4,}/)
    if (!isValidTel) {
      errorTel.value = t("form.error.invalid-tel")
    } else {
      errorTel.value = ''
    }
  }
}

function submitForm() {

  for (const [key, value] of Object.entries(formValues)) {
    if (key == 'name') {
      if (!value) {
        errorName.value = t("form.error.empty")
        continue
      }

    } else if (key == 'mail') {
      if (!value) {
        errorMail.value = t("form.error.empty")
        continue
      }
      let isValidMail = value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      if (!isValidMail) {
        errorMail.value = t("form.error.invalid-email")
        continue
      }
    } else if (key == 'tel') {
      if (!value) {
        console.log(Boolean(value))
        errorTel.value = t("form.error.empty")
        continue
      }
      let isValidTel = value.match(/\d{4,}/)
      if (!isValidTel) {
        errorTel.value = t("form.error.invalid-tel")
        continue
      }
    }
  }

  if (errorMail.value || errorName.value || errorTel.value) {
    return
  }

  formError.value = ''
  axios.post('https://backend.delayfix.appwise.dev/api/recompensate/', formValues)
      .then(() => {
        router.push('/thank-you')
      })
      .catch(err => {
        formError.value = t("form.error.global")
      })
}

</script>

<style lang="scss" scoped>

</style>
