<template>
  <div class="input" :class="{'input--error': error}">
    <div class="input__label" v-text="label"/>
    <vue-tel-input 
      id="phone"
      type="tel"
      mode="international"
      :autoFormat=false
      :dropdownOptions="{
        showFlags: true
      }"
      :inputOptions="{
        placeholder,
        styleClasses: 'input__el'
      }"
      v-model="phone"
      :preferred-countries="['pl', 'cz', 'de']"
      @blur="$emit('input-blur', $event.target)"
      @input="$emit('input-change', countryCode+phone)"
      @country-changed="countryChanged"
    />
    <div v-if="error" class="error">
      {{ error }}
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

const phone = ref(null)
const countryCode = ref('')

const countryChanged = (country) => {
  countryCode.value = country.dialCode
}

const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  error: {
    type: String, 
    default: ''
  }
})

</script>

<style lang="scss">

.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  &__label {
    color: #303030;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 8px;
  }

  &__el {
    height: 36px;
    padding: 0px 13px;
    border-radius: 4px;
    border: 1px solid #CFCFCF;
    background: #F7F7F7;
    &::placeholder {
      color: #848A9E;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .vue-tel-input {
    border: none;
  }

  .vti {
    &__dropdown {
      border: none;
    }
  }

  &--error input {
    border: 2px solid red;
  }
}

.error {
  color: red;
  font-family: Inter;
  margin-top: 5px;
  font-size: 13px;
}

@media screen and (min-width: 1024px) {
  .input {
    margin-bottom: 25px;
  }
}
</style>
