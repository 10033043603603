<template>
    <div 
        class="dropdown"
        :class="{show: showMenu}"
        @mouseenter="showMenu = true"
        @mouseleave="showMenu = false"
        @click="showMenu=!showMenu"
    >
        <div class="dropdown-toggle">{{ locale.toUpperCase() }}</div>
        <img 
            src="../../assets/svg/dropdownIcon.svg" 
            alt="" 
            class="dropdown-icon"
        >
        <ul class="dropdown-menu">
            <li
                v-for="aLocale in availableLocales"
                v-show="aLocale !== locale"
                :key="`locale-${aLocale}`"
                class="dropdown-menu__link"
                @click="locale = aLocale"
            >
                {{ aLocale.toUpperCase() }}
            </li>
        </ul>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { ref } from 'vue';

const { availableLocales, locale } = useI18n() 
const showMenu = ref(false)

</script>

<style lang="scss" scoped>

</style>