const cs = {
  "form": {
    "title": "Získejte odškodnění s DelayFix",
    "paragraph": "Stačí, když odešlete svou žádost, a náš konzultant vás bude kontaktovat, aby domluvil podrobnosti.",
    "name": "Jméno",
    "number": "Telefonní číslo",
    "mail": {
      "label": "E-mailová adresa",
      "placeholder": "adresa{'@'}email.cz"
    },
    "comment": {
      "btn": "Přidat komentář",
      "label": "Váš komentář",
      "label-sm": "(volitelné)",
      "placeholder": "Chcete k vaší žádosti něco přidat?"
    },
    "error": {
      "empty": "Toto pole nesmí být prázdné",
      "invalid-email": "Zadejte platnou e-mailovou adresu",
      "invalid-tel": "Zadejte platné telefonní číslo",
      "global": "Wystąpił błąd. Spróbuj ponownie później.",
    },
    "btn": "Odeslat"
  },
  "why": {
    "title": "Proč DelayFix?",
    "paragraph": "Máme bohaté zkušenosti s řešením nejtěžších případů, včetně předběžných řízení před Soudním dvorem Evropské unie (CJEU). Nebojíme se výzev. Pokud je to nutné, naši právníci jsou připraveni letět do nejvzdálenějších koutů světa, aby osobně dohlédli na vymáhání oprávněného odškodnění."
  },
  "reviews": {
    "title": "Recenze našich klientů",
    "description": "Přečtěte si recenze tisíců spokojených klientů DelayFix",
  },
  "footer": {
    "text": "Podporujeme cestující postižené zpožděnými nebo zrušenými lety",
    "contact": "Doporučujeme vám kontaktovat nás od pondělí do pátku, od 9:00 do 17:00",
    "terms": "Podmínky služby",
    "privacy": "Zásady ochrany osobních údajů",
    "capital": "Výše základního kapitálu:"
  },
  "thank-you": {
    "title": "Děkujeme!",
    "paragraph": "Vaše žádost byla odeslána. Náš konzultant vás bude kontaktovat, aby domluvil podrobnosti."
  }
}

export default cs;
