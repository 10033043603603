<template>
  <ContentContainer class="how-section">
    <h2 class="how-section__title" :style="[content['color'] ? {color: content['color']} : '']">
      {{ content['how_it_works_section_title_' + langCode.value] ?? '' }}
    </h2>
    <p class="how-section__description">
      {{ content['how_it_works_section_description_' + langCode.value] ?? '' }}

    </p>
    <div class="items-wrap">
      <div
          v-for="(item, index) in content.how_it_works_section_steps"
          :key="index"
          :class="`item--${index}`"
          class="item"
      >
        <img :src="iconList[index]" class="item__number" alt="">
                <div class="item__info-box">

                  <h3 class="item__title" :style="[content['color'] ? {color: content['color']} : '']">
                    {{ item['title_' + langCode.value]}}
                  </h3>

                  <p class="item__description">
                    {{ item['text_' + langCode.value]}}
                  </p>
                </div>
      </div>
    </div>
    <BaseButton
        class="how-section__btn"
        :value='content["how_it_works_section_button_text_" + langCode.value] ?? ""'
        @handleClick="scrollToForm()"
    />
  </ContentContainer>
</template>

<script setup lang="ts">
import ContentContainer from '@/layout/ContentContainer.vue'
import BaseButton from '@/components/Base/BaseButton.vue';
import {useI18n} from 'vue-i18n'
import {useStore} from "vuex";
import {ComponentInstance, computed, ref} from 'vue'
import numberOne from '@/assets/svg/numberOne.svg'
import numberTwo from '@/assets/svg/numberTwo.svg'
import numberThree from '@/assets/svg/numberThree.svg'

const {t} = useI18n()
const store = useStore()

const langCode = computed(() => {
  return useI18n().locale
})

const content = computed(() => {
  return store.state.pageContent
})

const iconList = [numberOne, numberTwo, numberThree]

const scrollToForm = () => {
  document?.querySelector('.form')?.scrollIntoView({behavior: "smooth"})
}
</script>

<style lang="scss" scoped>

</style>
