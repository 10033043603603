<template>
    <div>
        <ContentContainer class="why">
            <div class="why__text">
                <h2 class="why__text__title">
                    {{ t("why.title") }}
                </h2>
                <p class="why__text__paragraph">
                    {{ t("why.paragraph") }}
                </p>
            </div>
            <img :src="image" alt="" class="why__img">
        </ContentContainer>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import ContentContainer from '@/layout/ContentContainer.vue';
import { useI18n } from 'vue-i18n'
const { t } = useI18n() 

const image = ref('https://media.delayfix.appwise.dev/_/rs:fill:732:376:1/g:fp:0.10:0.75/aHR0cHM6Ly9iYWNrZW5kLWNtcy5kZWxheWZpeC5hcHB3aXNlLmRldi9tZWRpYS9pbWFnZXMvMjAyMi8wOC0xMC9EZWxheUZpeF9iaWdfMjAyMDExMDQtOF9TamFvSmpDLmpwZw')

</script>

<style lang="scss" scoped>

</style>