import axios from 'axios'

const apiClient = axios.create({
  baseURL: `https://backend-cms.delayfix.appwise.dev/public/whitelabels/`,
  timeout: 10000
})

function getPageDetails(partner) {
    return apiClient.get('/' + partner)
}

export default getPageDetails
