const pl = {
    "form": {
        "title": "Uzyskaj odszkodowanie z DelayFix",
        "paragraph": "Wystarczy, że prześlesz swoje zgłoszenie, a nasz konsultant skontaktuje się z Tobą, aby ustalić szczegóły.",
        "name": "Imię",
        "number": "Numer telefonu",
        "mail": {
            "label": "Adres e-mail",
            "placeholder": "adres{'@'}email.pl"
        },
        "comment": {
            "btn": "Dodaj komentarz",
            "label": "Twój komentarz",
            "label-sm": "(opcjonalnie)",
            "placeholder": "Czy chcesz dodać coś do swojego zgłoszenia?"
        },
        "error": {
            "empty": "To pole nie może być puste",
            "invalid-email": "Wprowadź poprawny adres email",
            "invalid-tel": "Wprowadź poprawny numer telefonu",
            "global": "Wystąpił błąd. Spróbuj ponownie później.",
        },
        "btn": "Wyślij"
    },
    "why": {
        "title": "Dlaczego DelayFix?",
        "paragraph": "Mamy bogate doświadczenie w prowadzeniu najtrudniejszych spraw, w tym postępowań prejudycjalnych przed Trybunałem Sprawiedliwości Unii Europejskiej (TSUE). Nie boimy się wyzwań. Jeśli trzeba, nasi prawnicy są gotowi polecieć w najdalsze zakątki świata, aby osobiście dopilnować egzekucji należnego odszkodowania."
    },
    "reviews": {
        "title": "Opinie naszych klientów",
        "description": "Poznaj opinie tysięcy zadowolonych klientów DelayFix",
    },
    "footer": {
        "text": "Wspieramy Pasażerów poszkodowanych w wyniku opóźnionych lub odwołanych lotów",
        "contact": "Zachęcamy Państwa do kontaktu od poniedziałku do piątku w godzinach: 9:00 - 17:00",
        "terms": "Regulamin",
        "privacy": "Polityka Prywatności",
        "capital": "Wysokość kapitału zakładowego:"
    },
    "thank-you": {
        "title": "Dziękujemy!",
        "paragraph": "Twoje zgłoszenie zostało wysłane. Nasz konsultant skontaktuje się z Tobą, aby ustalić szczegóły."
    }
}

export default pl
