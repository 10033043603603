<template>
    <Header />
    <RouterView />
    <Footer />
</template>

<script setup>
import getPageDetail from '@/service/pageInfo'
import Header from '@/layout/Header.vue'
import Footer from '@/layout/Footer.vue'
import { RouterView } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore()
const partner = new URLSearchParams(window.location.search).get('partner');

getPageDetail(partner)
    .then(response => {
        store.commit('updatePageContent', response.data)
    })
    .catch(error => console.log(error))

</script>

<style lang="scss">
@import '@/assets/styles/main.scss';
</style>
