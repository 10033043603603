import { createStore } from "vuex";

const store = createStore({
    state() {
        return {
            pageContent: {
            }
        }
    },
    mutations: {
        updatePageContent (state, data) {
            state.pageContent = data
        },
    }
})

export default store
